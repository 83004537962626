import TextButton from "components/Buttons/TextButton/TextButton";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { DnsRecordSchema, DnsRecordSchemaType } from "features/Domains/api/commands/DnsRecordSchema";
import { useCreateRecordMutation } from "features/Domains/api/dnsZonesApi";
import { DialogPortal } from "hooks/DialogPortal";
import { useAppForm } from "hooks/useAppForm";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import DnsRecordInputs from "./components/DnsRecordInputs/DnsRecordInputs";
import { useThemedClassName } from "contexts/ThemeContext";
import Typo from "components/Typo/Typo";
import { MediaQueries } from "utils/MediaQueries";

interface IProps {
    dnsZoneId: string
    show: boolean
    onClose: () => void
    dnsRecordId?: string
}

const DnsRecordDialog: FC<IProps> = ({
    dnsZoneId,
    show,
    onClose,
    dnsRecordId,
}) => {
    const baseClassName = "dns-record-dialog"
    const className = useThemedClassName(baseClassName)

    const customerId = useCustomerId()

    const initData = useInitData(customerId ?? "", dnsZoneId)

    const {
        form,
        formReset,
        onFormSubmit,
        isLoading,
        isSuccess
    } = useAppForm(
        initData,
        DnsRecordSchema,
        useCreateRecordMutation
    )

    const onCloseInternal = useCallback(() => {
        onClose()
        formReset()
    }, [onClose, formReset])

    const recordOptions = [
        "A",
        "AAAA",
        "CNAME",
        "MX",
        "TXT",
        "NS",
        "PTR",
        "SRV"
    ]

    useEffect(() => {
        if (isSuccess) onCloseInternal()
    }, [isSuccess, onCloseInternal])

    return (
        <DialogPortal
            breakpoint={"medium"}
            type={"basic"}
            className={className}
            headline="Create DNS record"
            onCloseClick={onCloseInternal}
            show={show}
            content={
                <FormProvider {...form}>
                    <form onSubmit={onFormSubmit}>
                        <div className={`${baseClassName}__form`}>
                            {
                                !MediaQueries.medium() &&
                                <Typo.Headline size="small">Create DNS record</Typo.Headline>
                            }

                            <DnsRecordInputs isLoading={isLoading} recordOptions={recordOptions} />

                            <div className={`${baseClassName}__buttons`}>
                                <TextButton type="button" textType="text-only" color="blue" onClick={onCloseInternal}>Cancel</TextButton>
                                <TextButton type="submit" textType="text-only" color="blue">Create</TextButton>
                            </div>
                        </div>

                    </form>
                </FormProvider>
            }
            isLoading={false}
        />
    )
}

const useInitData = (customerId: string, dnsZoneId: string, dnsRecordId?: string): DnsRecordSchemaType => {
    return {
        customerId,
        dnsZoneId,
        dnsRecordId,
        type: "A",
        host: "@",
        content: "",
        ttl: 3600
    }
}

export default DnsRecordDialog