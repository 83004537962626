import { z } from 'zod'

export const DnsRecordSchema = z.object({
    customerId: z.string().optional(),
    dnsZoneId: z.string().optional(),
    dnsRecordId: z.string().optional(),
    type: z.string().min(1),
    host: z.string().min(1),
    content: z.string().min(1),
    ttl: z.number(),
    priority: z.number().optional(),
    weight: z.number().optional(),
    port: z.number().optional()
})

export type DnsRecordSchemaType = z.infer<typeof DnsRecordSchema>