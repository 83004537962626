import { api } from "stores/redux/reducers/api";
import { GetRecordsByZoneId } from "./queries/GetDnsRecordsByZoneId";
import { Paginated } from "types/models/Paginated";
import { DnsRecord } from "./responses/DnsRecord";
import { DnsRecordSchemaType } from "./commands/DnsRecordSchema";

const dnsZonesApi = api.injectEndpoints({
    endpoints: build => ({
        getRecordsByZoneId: build.query<Paginated<DnsRecord>, GetRecordsByZoneId>({
            query: ({ customerId, dnsZoneId, page, pageSize }) => `customers/${customerId}/dns_zones/${dnsZoneId}/dns_records?page=${page}&pageSize=${pageSize}`,
            providesTags: (result) =>
                result
                    ? [...result.items.map(({ id }) => ({ type: "DnsRecord" as const, id })), { type: "DnsRecord", id: "LIST" }]
                    : [{ type: "DnsRecord", id: "LIST" }]
        }),
        createRecord: build.mutation<DnsRecord, DnsRecordSchemaType>({
            query: ({ customerId, dnsZoneId, dnsRecordId: _, ...body }) => ({
                url: `customers/${customerId}/dns_zones/${dnsZoneId}/dns_records`,
                body
            }),
            invalidatesTags: [{ type: "DnsRecord", id: "LIST" }]
        })
    })
})

export const {
    useGetRecordsByZoneIdQuery,
    useCreateRecordMutation
} = dnsZonesApi