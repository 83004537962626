import { api } from "../../../stores/redux/reducers/api";
import { Paginated } from "../../../types/models/Paginated";
import { Registrant } from "./responses/Registrant";
import { GetRegistrantsByCustomerId } from "./queries/GetRegistrantsByCustomerId";
import { CreateRegistrantSchemaType } from "./commands/CreateRegistrantSchema";
import { UpdateRegistrantSchemaType } from "./commands/UpdateRegistrantSchema";

const registrantsApi = api.injectEndpoints({
    endpoints: build => ({
        getRegistrantsByCustomerId: build.query<Paginated<Registrant>, GetRegistrantsByCustomerId>({
            query: (
                {
                    customerId,
                    page,
                    pageSize
                }
            ) => `customers/${customerId}/registrants?page=${page}&pageSize=${pageSize}`,
            providesTags: (result) =>
                result
                    ? [...result.items.map(({ id }) => ({ type: "Registrant" as const, id })), { type: "Registrant", id: "LIST" }]
                    : ["Registrant"]
        }),
        getRegistrant: build.query<Registrant, { customerId: string, registrantId: string }>({
            query: ({ customerId, registrantId }) => `customers/${customerId}/registrants/${registrantId}`,
            providesTags: (response) => [{ type: "Registrant", id: response?.id }]
        }),
        createRegistrant: build.mutation<Registrant, CreateRegistrantSchemaType>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants`,
                method: "POST",
                body: data
            }),
            invalidatesTags: (_, error, __) => error ? [] : [{ type: "Registrant", id: "LIST" }]
        }),
        updateRegistrant: build.mutation<Registrant, UpdateRegistrantSchemaType>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants/${data.id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: (response) => [{ type: "Registrant", id: response?.id }]
        }),
        deleteRegistrant: build.mutation<Registrant, { customerId: string, id: string }>({
            query: (data) => ({
                url: `customers/${data.customerId}/registrants/${data.id}`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => error ? [] : [{ type: "Registrant", id: "LIST" }]
        })
    })
})

export const {
    useGetRegistrantsByCustomerIdQuery,
    useCreateRegistrantMutation,
    useGetRegistrantQuery,
    useUpdateRegistrantMutation,
    useDeleteRegistrantMutation
} = registrantsApi