import React, {FC, useMemo} from "react";
import {ResourceStatus} from "../../types/models/ResourceStatus";
import {useThemeContext} from "../../contexts/ThemeContext";
import MaterialSymbol from "../MaterialSymbol/MaterialSymbol";

interface IProps {
    status: string
    inverted?: boolean
}

const StatusIndicator: FC<IProps> = ({status, inverted}) => {
    const {getThemeClassName} = useThemeContext()

    const baseClassName = "status-indicator"

    const icon = useMemo(() => {
        switch (status.toLowerCase()) {
            case ResourceStatus.Ready.toLowerCase():
                return "check"
            case ResourceStatus.Pending.toLowerCase():
                return "clock_loader_40"
            case ResourceStatus.Failed.toLowerCase():
                return "error"
            default:
                return "pending"
        }
    }, [status])

    return (
        <div className={`
            ${baseClassName} 
            ${getThemeClassName(baseClassName)}
            ${status === ResourceStatus.Pending && baseClassName + "--pending"}
            ${inverted && baseClassName + "--inverted"}
            ${status === ResourceStatus.Failed && baseClassName + "--failed"}
            ${status === ResourceStatus.Ready && baseClassName + "--ready"}
        `}>
            <MaterialSymbol name={icon}/>
        </div>
    )
}

export default StatusIndicator