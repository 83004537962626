import Loader from "components/Loader/Loader";
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol";
import OutlinedTextField from "components/TextFields/OutlinedTextField/OutlinedTextField";
import { useThemedClassName } from "contexts/ThemeContext";
import { useOutsideClickDetection } from "hooks/useOutsideComponentDetection";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

interface IProps<TValue> {
    name: string
    label: string
    labelSelector: (value: TValue) => string
    valueSelector: (value: TValue) => string
    options: TValue[]
    onChange: (value: TValue) => void
    className?: string
    isLoading?: boolean
    selected?: TValue
    onSearch?: (value: string) => void,
    searchInterval?: number,
    closeOnOptionClick?: boolean
}

const Menu = forwardRef(MenuInner) as <T>(
    props: IProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof MenuInner>;

function MenuInner<TValue>(
    {
        name,
        label,
        labelSelector,
        valueSelector: idSelector,
        options,
        onChange,
        className,
        isLoading,
        selected,
        onSearch,
        searchInterval,
        closeOnOptionClick = false
    }: IProps<TValue>, ref: React.ForwardedRef<HTMLInputElement>
) {
    if ((searchInterval && !onSearch) || (!searchInterval && onSearch))
        console.error("You are setting searchInterval while not setting onSearch or the other way around.")

    const baseClassName = "menu"
    const themedClassName = useThemedClassName(baseClassName)

    const [innerInputValue, setInnerInputValue] = useState(selected ? labelSelector(selected) : "")
    const [optionsOpen, setOptionsOpen] = useState(false)

    const localRef = useRef<HTMLInputElement>(null)
    const optionsRef = useRef<HTMLDivElement>(null)

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
        onSearch && setInnerInputValue(e.target.value)

    const onOptionClick = (value: TValue) => {
        closeOnOptionClick && setOptionsOpen(false)

        onChange(value)
    }

    useImperativeHandle(ref, () => localRef.current!, [])

    useOutsideClickDetection(optionsRef, () => setOptionsOpen(false))

    useEffect(() => {
        let timeout: number;

        if (onSearch && searchInterval) {
            timeout = window.setTimeout(() => onSearch(innerInputValue), searchInterval)
        }

        return () => {
            if (timeout) clearTimeout(timeout)
        }
    }, [innerInputValue, searchInterval, onSearch])

    useEffect(() => {
        setInnerInputValue(selected ? labelSelector(selected) : "")
    }, [selected, labelSelector, setInnerInputValue])

    return (
        <div className={`${themedClassName} ${className}`}>
            <div className={`${baseClassName}__input-container`}>
                <OutlinedTextField
                    label={label}
                    value={innerInputValue}
                    name={name}
                    onChange={onInputChange}
                    onClick={() => setOptionsOpen(o => !o)}
                />

                <div className={`${baseClassName}__arrow-container`}>
                    {
                        isLoading
                            ? <Loader />
                            : <MaterialSymbol name="keyboard_arrow_down"
                                className={`${baseClassName}__arrow ${optionsOpen && `${baseClassName}__arrow--show`}`}
                            />
                    }
                </div>
            </div>

            <div className={`${baseClassName}__options ${optionsOpen && `${baseClassName}__options--show`}`} ref={optionsRef}>
                {
                    options.map((option) => (
                        <div onClick={() => onOptionClick(option)} key={`${labelSelector(option)}-${idSelector(option)}`}>
                            <p>{labelSelector(option)}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Menu