import { RoutingDefinition } from "app/routes/RoutingDefinition";
import FilledButton from "components/Buttons/FilledButton/FilledButton";
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol";
import Pane from "components/Pane/Pane";
import PaneHeader from "components/PaneHeader/PaneHeader";
import StatusIndicator from "components/StatusIndicator/StatusIndicator";
import Table from "components/Table/Table";
import { useThemedClassName } from "contexts/ThemeContext";
import { useCustomerId } from "features/Auth/hooks/useCustomerId";
import { useGetRecordsByZoneIdQuery } from "features/Domains/api/dnsZonesApi";
import { useGetDomainNameByIdQuery } from "features/Domains/api/domainNamesApi";
import { DnsRecord } from "features/Domains/api/responses/DnsRecord";
import DnsRecordDialog from "features/Domains/components/DnsRecordDialog/DnsRecordDialog";
import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MediaQueries } from "utils/MediaQueries";

const DomainDetailPage: FC = () => {
    const baseClassName = "domain-detail-page"
    const className = useThemedClassName(baseClassName)

    const [showDialog, setShowDialog] = useState<boolean>(false)

    const { id } = useParams<{ id: string }>()
    const customerId = useCustomerId()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { data: domainName } = useGetDomainNameByIdQuery({
        customerId: customerId ?? "",
        domainNameId: id ?? ""
    }, {
        skip: !id || !customerId
    })

    const { data: paginatedRecords } = useGetRecordsByZoneIdQuery({
        customerId: customerId ?? "",
        dnsZoneId: domainName?.dnsZone?.id ?? "",
        page: 0,
        pageSize: 10
    }, {
        skip: !domainName || !domainName.dnsZone
    })

    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])
    const pageSize = 10

    const back = useCallback(() => {
        navigate(RoutingDefinition.domainNames.navigationLink!)
    }, [navigate])

    return (
        <div className={`${className} page-container`}>
            <Pane
                disableIfAccountNotComplete
                title={domainName ? `${domainName.sld}.${domainName.tld}` : ""}
                backPath={RoutingDefinition.domainNames.navigationLink!}
            >
                {
                    !MediaQueries.medium() &&
                    <div className={`${baseClassName}__table-options`}>
                        <PaneHeader
                            parentTitle={"Domains"}
                            title={`${domainName?.sld}.${domainName?.tld}`}
                            onBackClick={back}
                            backButton
                        />
                    </div>
                }

                {
                    <DnsRecordDialog
                        dnsZoneId={domainName?.dnsZone?.id ?? ""}
                        show={showDialog}
                        onClose={() => setShowDialog(false)}
                    />
                }

                <div className={`${baseClassName}__content`}>
                    <div className={`${baseClassName}__header`}>
                        <FilledButton color={"blue"} textType="icon-text" onClick={() => setShowDialog(true)}>
                            <MaterialSymbol name="add" /> Create
                        </FilledButton>
                    </div>

                    <Table<DnsRecord>
                        className={""}
                        columns={[
                            {
                                name: "Type",
                                render: (item) => <p>{item.type}</p>
                            },
                            {
                                name: "Host",
                                render: (item) => <p>{item.host === "@" ? `${domainName?.sld}.${domainName?.tld}` : item.host}</p>
                            },
                            {
                                name: "Content",
                                render: (item) => <p>{item.priority} {item.weight} {item.port} {item.content}</p>
                            },
                            {
                                name: "TTL",
                                render: (item) => <p>{item.ttl}</p>
                            },
                            {
                                name: "",
                                render: (item) => <StatusIndicator status={item.status} />
                            }
                        ]}
                        items={paginatedRecords}
                        page={page}
                        pageSize={pageSize}
                        totalItems={paginatedRecords?.totalItems ?? 0}
                        isLoading={false}
                        isFetching={false}
                    />
                </div>
            </Pane>
        </div>
    )
}

export default DomainDetailPage